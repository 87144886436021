import React, { Component } from 'react';
import 'typeface-roboto';
import aboutFigure from '../../images/about-figure.png';
import btn_our_work from '../../images/btn-our-work.png';
export class Aboutus extends Component {
    static displayName = Aboutus.name;
    constructor(props) {

        super(props);
        this.state = {
            aboutUsContentP1: "Nami Studio MY is founded in 2022 by a Malaysian Software Engineer who has passion to working and learning on software application in our free time. We like to improve our ability in Software Programming .We are seeking for new experience, challenges and technologies while enjoy continuous learning."

        }
    }
  render() {
      return (
          <div>
              <div class="paddingAbout">
                  <div class="responsive-container-block bigContainer">
                      <div class="responsive-container-block Container">
                          <img class="mainImg" src={aboutFigure} alt="aboutFigure"></img>
                          <div class="allText aboveText">
                              <h1 class="roboto-bold" style={{ textAlign: 'center' }}>About  <font color="#FF001F">Us</font></h1>
                              <hr style={{ width: '10%', margin: 'auto', color: 'white' }}></hr>
                              <div>&nbsp;</div>
                              <h2 class="roboto-light" style={{ textAlign: 'justify' }}>{this.state.aboutUsContentP1}</h2>
                              <div>&nbsp;</div>
                              <a href="/#/our-work">
                                  <img class="scaleOurWork" src={btn_our_work} alt="btn_our_work"></img>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    );
  }
}
