import React, { Component } from 'react';
import 'typeface-roboto';
import metmalaysia from '../../images/malaysiaweatherforecast_img.png';
import mytvmovie from '../../images/mytvmovie_img.png';
import dbsqlxmlconverter_img from '../../images/dbsqlxmlconverter_img.png';
import nchatApp from '../../images/nchatapp_img.png';
import jiiicasImg from '../../images/jiiicas_img.png';
import weather_img from '../../images/weather_img.png';
import { CustomFooter } from '../CustomFooter';

export class OurWork extends Component {
    static displayName = OurWork.name;
    constructor(props) {

        super(props);
        this.state = {
            aboutUsContentP1: "Nami Studio MY is founded in 2022 by a Malaysian Software Engineer who has passion to working and learning on software application in our free time. We like to improve our ability in Software Programming .We are seeking for new experience, challenges and technologies while enjoy continuous learning."

        }
    }
  render() {
      return (
          <div>
              <div class="paddingOurWork">

                  <h1 class="roboto-bold" style={{ textAlign: 'center' }}>Our <font color="#FF001F">Work</font></h1>
                  <hr style={{ width: '10%', margin: 'auto', color: 'white' }}></hr>

                  <div class="row" style={{ placeContent: 'center' }} >
                      <div class="col-12 col-md-6" style={{ alignSelf: 'center', textAlign: 'center' }}>
                          <div class="whiteBackground">
                              <tr >
                                  <td>
                                      <a href="#/our-work/nami-met-malaysia">
                                          <img class="scaleWorkLogo" src={metmalaysia} alt="metmalaysia" />
                                      </a>
                                  </td>
                              </tr>
                              <tr >
                                  <td> <p class="roboto-bold" style={{ color: 'black' }}>NAMI MET Malaysia</p></td>
                              </tr>
                          </div>
                      </div>
                      <div class="col-12 col-md-6" style={{ alignSelf: 'center', textAlign: 'center' }}>
                          <div class="whiteBackground">
                              <tr >
                                  <td>
                                      <a href="#/our-work/my-tv-movie">
                                          <img class="scaleWorkLogo" src={mytvmovie} alt="mytvmovie" />
                                      </a>
                                  </td>
                              </tr>
                              <tr >
                                  <td> <p class="roboto-bold" style={{ color: 'black' }}>MY TV Movie</p></td>
                              </tr>
                          </div>
                      </div>

                  </div>

                  <div class="row" style={{ placeContent: 'center' }} >
                      <div class="col-12 col-md-6" style={{ alignSelf: 'center', textAlign: 'center' }}>
                          <div class="whiteBackground">
                              <tr >
                                  <td>
                                      <a href="#/our-work/dbsqlxml-converter">
                                          <img class="scaleWorkLogo" src={dbsqlxmlconverter_img} alt="dbsqlxmlconverter_img" />
                                      </a>
                                  </td>
                              </tr>
                              <tr >
                                  <td> <p class="roboto-bold" style={{ color: 'black' }}>DBSQLXML Converter</p></td>
                              </tr>
                          </div>
                      </div>
                      <div class="col-12 col-md-6" style={{ alignSelf: 'center', textAlign: 'center' }}>
                          <div class="whiteBackground">
                              <tr >
                                  <td>
                                      <a href="#/our-work/nchat-app">
                                          <img class="scaleWorkLogo" src={nchatApp} alt="nchatApp" />
                                      </a>
                                  </td>
                              </tr>
                              <tr >
                                  <td> <p class="roboto-bold" style={{ color: 'black' }}>NChat App</p></td>
                              </tr>
                          </div>
                      </div>
                  </div>

                  <div class="row" style={{ placeContent: 'center' }} >
                      <div class="col-12 col-md-6" style={{ alignSelf: 'center', textAlign: 'center' }}>
                          <div class="whiteBackground">
                              <tr >
                                  <td>
                                      <a href="#/our-work/jiiicas">
                                          <img class="scaleWorkLogo" src={jiiicasImg} alt="jiiicasImg" />
                                      </a>
                                  </td>
                              </tr>
                              <tr >
                                  <td> <p class="roboto-bold" style={{ color: 'black' }}>JIIICas Website</p></td>
                              </tr>
                          </div>
                      </div>
                      <div class="col-12 col-md-6" style={{ alignSelf: 'center', textAlign: 'center' }}>
                          <div class="whiteBackground">
                              <tr >
                                  <td>
                                      <a href="#/our-work/malaysia-weather-forecast-service">
                                          <img class="scaleWorkLogo" src={weather_img} alt="weather_img" />
                                      </a>
                                  </td>
                              </tr>
                              <tr >
                                  <td> <p class="roboto-bold" style={{ color: 'black' }}>Malaysia Weather Forecast Service</p></td>
                              </tr>
                          </div>
                      </div>
                  </div>
              </div>

              <CustomFooter/>
          </div>
    );
  }
}
