import { PersonalProjectBoard } from "./PersonalProjectBoard";
import { ContactUsBoard } from "./ContactUsBoard";
import { HomeBoard } from "./HomeBoard";
import { MyTVMovie } from "./OurWork/MyTVMovie";
import { DBSQLXMLConverter } from "./OurWork/DBSQLXMLConverter";
import { NChatApp } from "./OurWork/NChatApp";
import { WeatherServiceAPIBoard } from "./WeatherServiceAPIBoard";
import { WeatherForecastApp } from "./WeatherForecastApp";
import { SimpleCalculatorBoard } from "./SimpleCalculatorBoard";
import { SimpleTodoBoard } from "./SimpleTodoBoard";
import { NamiMetMalaysia } from "./OurWork/NamiMetMalaysia";
import { OurWork } from "./Home/OurWork";
import { JiiicasWeb } from "./OurWork/JiiicasWeb";
import { MalaysiaWeatherForecastService } from "./OurWork/MalaysiaWeatherForecastService";

const AppRoutes = [
  {
    index: true,
    element: <HomeBoard />
  },
   {
       path: '/our-work',
       element: <OurWork />
    },
    {
        path: '/our-work/nami-met-malaysia',
        element: <NamiMetMalaysia />
    },
    {
        path: '/our-work/my-tv-movie',
        element: <MyTVMovie />
    },
    {
        path: '/our-work/dbsqlxml-converter',
        element: <DBSQLXMLConverter />
    },
    {
        path: '/our-work/nchat-app',
        element: <NChatApp />
    },
    {
        path: '/contact-us',
        element: <ContactUsBoard />
    },
    {
        path: '/our-work/jiiicas',
        element: <JiiicasWeb />
    }
    ,
    {
        path: '/our-work/malaysia-weather-forecast-service',
        element: <MalaysiaWeatherForecastService />
    },
  //  {
  //      path: '/personal-project/simple-calculator',
  //      element: <SimpleCalculatorBoard />
  //  },
  //  {
  //      path: '/personal-project/simple-todo',
  //      element: <SimpleTodoBoard />
  //  }
  //{
  //  path: '/fetch-data',
  //  element: <FetchData />
  //}
];

export default AppRoutes;
