import React, { Component } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import { Link } from 'react-router-dom';
import '../styles/NavMenu.css';
import barLogo from '../images/bar-logo.png';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleHome = this.toggleHome.bind(this);
        this.state = {
            collapsed: true,
            homeOpen: false
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    toggleHome() {
        this.setState({
            homeOpen: !this.state.homeOpen
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/"><img class="barlogo" src={barLogo} alt="barLogo" /></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="barButton" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            <Dropdown nav isOpen={this.state.homeOpen} toggle={this.toggleHome} style={{ paddingRight: "15px" }}>
                                <DropdownToggle nav caret className="home-link" to="/#/">
                                    <strong>HOME</strong>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href="/#/#aboutus">ABOUT US</DropdownItem>
                                 {/*   <DropdownItem href="/#/#ourwork">OUR WORK</DropdownItem>*/}
                                </DropdownMenu>
                            </Dropdown>
                            <NavItem style={{ paddingRight: "25px" }}>
                                <NavLink tag={Link} className="home-link" to="/our-work"><strong>OUR WORK</strong></NavLink>
                            </NavItem>
                            <NavItem style={{ paddingRight: "25px" }}>
                                <NavLink tag={Link} className="home-link" to="/contact-us"><strong>CONTACT US</strong></NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
