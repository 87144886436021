import React, { Component } from 'react';
import btn_about_us from '../../images/btn_about_us.png';
import icon_facebook from '../../images/icon_facebook.png';
import icon_instagram from '../../images/icon_instagram.png';
import icon_play_store from '../../images/icon_play_store.png';
import icon_linkedin from '../../images/icon_linkedin.png';
import 'typeface-roboto';
import mainLogo from '../../images/main-logo.png';
export class Main extends Component {
    static displayName = Main.name;

  render() {
      return (
          <div>
              <div class="paddingMain">
                  <tr>
                      <td>
                          <p class="roboto-medium" style={{ fontSize: '4vw' }}>Hello, It's Us,</p>
                          <p class="roboto-medium" style={{ fontSize: '5vw' }}>NAMI STUDIO MY</p>
                          <p class="roboto-light" style={{ fontSize: '3vw' }}>Malaysian Software Engineer who has passion to
                              working and learning on software application.
                          </p>
                      </td>
                      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                          <img class="scaleMainLogo" src={mainLogo} alt="mainLogo"></img>
                      </td>
                  </tr>
                  <div>&nbsp;</div>
                  <table>
                      <tr>
                          <td>
                              <a href="https://www.facebook.com/profile.php?id=100085342825720" target="_blank" rel="noreferrer">
                                  <img class="scaleSocMedLogo" src={icon_facebook} alt="icon_facebook"></img>
                              </a>
                              <a href="https://www.instagram.com/namistudio.my/" target="_blank" rel="noreferrer">
                                  <img class="scaleSocMedLogo" src={icon_instagram} alt="icon_instagram"></img>
                              </a>
                              <a href="https://play.google.com/store/apps/developer?id=Nami+Apps" target="_blank" rel="noreferrer">
                                  <img class="scaleSocMedLogo" src={icon_play_store} alt="icon_play_store"></img>
                              </a>
                              <a href="https://www.linkedin.com/company/nami-studio" target="_blank" rel="noreferrer">
                                  <img class="scaleSocMedLogo" src={icon_linkedin} alt="icon_linkedin"></img>
                              </a>



                          </td>
                      </tr>
                  </table>
                  <div>&nbsp;</div>
                  <a href="/#/#aboutus">
                      <img class="scaleAboutus" src={btn_about_us} alt="btn_about_us"></img>
                  </a>

              </div>
          </div>
    );
  }
}
